import Immutable from 'immutable';

import {
  CNP_PAYMENT_REQ_STORE_KEY,
  POST_CNP_PAYMENT_REQUEST,
  POST_CNP_PAYMENT_SUCCESS,
  POST_CNP_PAYMENT_FAILURE,
} from './constants';

import {
  failedRequestState,
  successRequestState,
  sendingRequestState,
} from '../../utils/isRequestInProgress';

export const initialState = Immutable.fromJS({
  requestStatus: {
    [CNP_PAYMENT_REQ_STORE_KEY]: '',
  },
});

export default function(state = initialState, action) {
  switch (action.type) {
    case POST_CNP_PAYMENT_REQUEST:
      return sendingRequestState(state, CNP_PAYMENT_REQ_STORE_KEY);

    case POST_CNP_PAYMENT_SUCCESS:
      return successRequestState(state, CNP_PAYMENT_REQ_STORE_KEY).merge({});

    case POST_CNP_PAYMENT_FAILURE:
      return failedRequestState(state, CNP_PAYMENT_REQ_STORE_KEY).merge({
        error: action.error,
        canBeResolved: true,
      });

    default:
      return state;
  }
}
