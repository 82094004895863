export const STORE_NAME = 'PaymentForm';

export const POST_FUNDING_PAYMENT_CARD_REQUEST =
  'POST_FUNDING_PAYMENT_CARD_REQUEST';
export const POST_FUNDING_PAYMENT_CARD_LOADING =
  'POST_FUNDING_PAYMENT_CARD_LOADING';
export const POST_FUNDING_PAYMENT_CARD_SUCCESS =
  'POST_FUNDING_PAYMENT_CARD_SUCCESS';
export const POST_FUNDING_PAYMENT_CARD_FAILURE =
  'POST_FUNDING_PAYMENT_CARD_FAILURE';

export const CNP_PAYMENT_REQ_STORE_KEY = 'CNP_PAYMENT';
export const POST_CNP_PAYMENT_REQUEST = 'PaymentForm/POST_CNP_PAYMENT_REQUEST';
export const POST_CNP_PAYMENT_SUCCESS = 'PaymentForm/POST_CNP_PAYMENT_SUCCESS';
export const POST_CNP_PAYMENT_FAILURE = 'PaymentForm/POST_CNP_PAYMENT_FAILURE';

const FULL_NAME = 'full_name';
const MONTH = 'month';
const YEAR = 'year';
const CARD_TYPE = 'card_type';
const LAST_FOUR_DIGITS = 'last_four_digits';

export const spreedlyResponseDataKeys = {
  FULL_NAME,
  MONTH,
  YEAR,
  CARD_TYPE,
  LAST_FOUR_DIGITS,
};

export const API_CARD_TYPE_MAP = {
  visa: 'VISA',
  master: 'MASTERCARD',
};

export const SUPPORTED_CARDS = [
  { type: 'visa', name: 'Visa' },
  { type: 'master', name: 'Mastercard' },
];
