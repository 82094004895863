import {
  POST_FUNDING_PAYMENT_CARD_REQUEST,
  POST_FUNDING_PAYMENT_CARD_LOADING,
  POST_FUNDING_PAYMENT_CARD_SUCCESS,
  POST_FUNDING_PAYMENT_CARD_FAILURE,
  POST_CNP_PAYMENT_REQUEST,
  POST_CNP_PAYMENT_SUCCESS,
  POST_CNP_PAYMENT_FAILURE,
} from './constants';

export const postFundingPaymentCardLoading = () => ({
  type: POST_FUNDING_PAYMENT_CARD_LOADING,
});
export const postFundingPaymentCardSuccess = ({ verificationUrl }) => ({
  type: POST_FUNDING_PAYMENT_CARD_SUCCESS,
  verificationUrl,
});
export const postFundingPaymentCardFailure = (error) => ({
  type: POST_FUNDING_PAYMENT_CARD_FAILURE,
  error,
});

export const postFundingPaymentCardRequest = (
  cardToken,
  cardHolderName,
  cardType,
  cardNumber,
  cardExpiry
) => ({
  type: POST_FUNDING_PAYMENT_CARD_REQUEST,
  cardToken,
  cardHolderName,
  cardType,
  cardNumber,
  cardExpiry,
});

export const postCNPPaymentRequest = (cardToken) => ({
  type: POST_CNP_PAYMENT_REQUEST,
  cardToken,
});

export const postCNPPaymentSuccess = () => ({
  type: POST_CNP_PAYMENT_SUCCESS,
});

export const postCNPPaymentFailure = (error) => ({
  type: POST_CNP_PAYMENT_FAILURE,
  error,
});
