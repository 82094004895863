import { connector } from 'redux/utils/connector';
import constructSearchParams from 'redux/utils/constructSearchParams';
import PartnerLoginComponent from 'components/modules/PartnerLogin';
import SignIn from 'components/pages/SignIn';
import ProxyLogin from 'components/pages/ProxyLogin';
import { LandingPageApi } from 'redux/modules/LandingPage/api';
import { RestoreApi } from 'redux/modules/Restore/api';
import { STORE_NAME, CUSTOMER_RELATIONSHIP_REFERRAL } from './constants';
import { SUPPORT_SELECTION_OPTIONS } from 'redux/modules/LandingPage/constants';
import { PartnerLoginApi } from './api';
import { setSupport } from 'redux/modules/LandingPage/actions';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import { serverLoad } from 'redux/modules/Persistence/actions';

export default connector(
  STORE_NAME,
  (state) => {
    const isRemote = LandingPageApi.isRemoteAppointment(state);
    const canSupportPartner = PartnerLoginApi.canSupportPartner(state);
    const selectedSupport = LandingPageApi.getSupportSelection(state);

    const isRemoteRestoreInProgress =
      RestoreApi.shouldApplyRestoredApplication(state) &&
      (isRemote || selectedSupport === SUPPORT_SELECTION_OPTIONS.remote);

    return {
      isRemote,
      canSupportPartner,
      selectedSupport,
      customerReferral: CUSTOMER_RELATIONSHIP_REFERRAL,
      remoteSupportEnabled: FeatureFlagsApi.getFlag(
        state,
        FLAGS.REMOTE_SUPPORT
      ),
      hostedId:
        constructSearchParams(location.href).exref ||
        constructSearchParams(location.href).position ||
        PartnerLoginApi.getHostedId(state),
      hasRestoreToken: RestoreApi.hasRestoreToken(state),
      isRemoteRestoreInProgress,
    };
  },
  {
    setSupport,
    restoreApplication: serverLoad.request,
  }
)(PartnerLoginComponent);

export const PartnerSSO = connector(STORE_NAME)(SignIn);
export const PartnerProxyLogin = connector(STORE_NAME)(ProxyLogin);
