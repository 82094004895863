import React from 'react';
import PropTypes from 'prop-types';
import PaymentForm from 'components/modules/PaymentForm';
import CardNotPresentForm from 'components/modules/PaymentForm/CardNotPresentForm';
import RemotePartnerNotice from 'components/modules/Remote/PaymentPartnerNotice';
import { SALES_SEMAFONE } from 'config';

const PaymentFormProvider = ({ remote, salesId, ...props }) => {
  if (salesId && SALES_SEMAFONE) {
    return <CardNotPresentForm {...props} />;
  }

  if (remote.active && !remote.isMainPeerDriving) {
    return <RemotePartnerNotice />;
  }

  return <PaymentForm {...props} />;
};

PaymentFormProvider.propTypes = {
  salesId: PropTypes.string,
  remote: PropTypes.shape({
    active: PropTypes.bool,
    isMainPeerDriving: PropTypes.bool,
  }),
};

export default PaymentFormProvider;
