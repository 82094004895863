import { connector } from 'redux/utils/connector';
import applicationCheck from 'redux/modules/ApplicationCheck';
import * as actionCreators from 'redux/modules';
import { STORE_NAME } from './constants';
import { ApplicationApi } from '../App/api';
import { OrderSummaryApi } from '../OrderSummary/api';
import { getFirstHolderName } from 'redux/modules/YourDetails/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';
import PaymentFormProvider from './provider';

export default connector(
  STORE_NAME,
  (state) => ({
    salesId: ApplicationApi.getSalesId(state),
    applicationId: ApplicationApi.getCurrentApplication(state),
    deposits: OrderSummaryApi.getDeposits(state),
    paymentAmount: OrderSummaryApi.getPaymentAmount(state),
    completeState: ApplicationApi.isComplete(state),
    holderName: getFirstHolderName(state),
    remote: {
      active: ApplicationApi.isRemoteSession(state),
      isMainPeerDriving: RemoteSessionApi.isMainPeerDriving(state),
    },
    appError: ApplicationApi.isComplete(state).error,
  }),
  {
    openErrorGlobalDialog: actionCreators.GlobalDialog.openErrorGlobalDialog,
    tryAgain: actionCreators.App.resetApplicationComplete,
  }
)(applicationCheck(PaymentFormProvider));
