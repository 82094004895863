export const STORE_NAME = 'PartnerLogin';

export const POST_PARTNER_LOGIN_REQUEST = 'POST_PARTNER_LOGIN_REQUEST';
export const POST_PARTNER_LOGIN_SUCCESS = 'POST_PARTNER_LOGIN_SUCCESS';
export const POST_PARTNER_LOGIN_FAILURE = 'POST_PARTNER_LOGIN_FAILURE';
export const PARTNER_TOKEN_REFRESHED = 'PARTNER_TOKEN_REFRESHED';
export const GET_PARTNER_PROFILE_SUCCESS = 'GET_PARTNER_PROFILE_SUCCESS';
export const PARTNER_LOGIN_CLEAR_LOADING = 'PARTNER_LOGIN_CLEAR_LOADING';
export const PARTNER_LOGOUT = 'PARTNER_LOGOUT';
export const JTC_GET_STARTED = 'JTC_GET_STARTED';
export const CUSTOMER_RELATIONSHIP_NEW = 'NEW';
export const CUSTOMER_RELATIONSHIP_REFERRAL = 'REFERRAL';
export const CUSTOMER_RELATIONSHIP_SUPPORTING = 'SUPPORTING';
export const PARTNER_LOGIN_UPDATE_FIELD = 'PARTNER_LOGIN_UPDATE_FIELD';
export const PARTNER_LOGIN_UPDATE_REFERRAL_FIELD =
  'PARTNER_LOGIN_UPDATE_REFERRAL_FIELD';
export const REFERRAL_FORM_VALIDATION = 'REFERRAL_FORM_VALIDATION';
export const RESET_REFERRER_FIELDS = 'RESET_REFERRER_FIELDS';
export const SET_SUPPORTING_ID_DIALOG = 'SET_SUPPORTING_ID_DIALOG';

export const SET_HOSTED_ID = 'SET_HOSTED_ID';
export const SET_SUPPORTING_ID = 'SET_SUPPORTING_ID';

export const POST_SUPPORTING_PARTNER_LOGIN_REQUEST =
  'POST_SUPPORTING_PARTNER_LOGIN_REQUEST';
export const POST_SUPPORTING_PARTNER_LOGIN_SUCCESS =
  'POST_SUPPORTING_PARTNER_LOGIN_SUCCESS';
export const POST_SUPPORTING_PARTNER_LOGIN_FAILURE =
  'POST_SUPPORTING_PARTNER_LOGIN_FAILURE';

export const ERROR_NOT_QUALIFIED = 'NOT_QUALIFIED';
export const MANDATORY = 'MANDATORY';
export const OPTIONAL = 'OPTIONAL';

export const SUPPORT_REQUIRED_MANDATORY = 'MANDATORY';
export const SUPPORT_REQUIRED_OPTIONAL = 'OPTIONAL';
export const SUPPORT_MODAL_STATES = [
  SUPPORT_REQUIRED_MANDATORY,
  SUPPORT_REQUIRED_OPTIONAL,
];
export const LANDING_HANDLE_ANALYTICS_PAGE = 'LANDING_HANDLE_ANALYTICS_PAGE';

export const SSO_VERIFY_REQUEST = 'SSO_VERIFY_REQUEST';
export const SSO_VERIFY_SUCCESS = 'SSO_VERIFY_SUCCESS';
export const SSO_VERIFY_FAILURE = 'SSO_VERIFY_FAILURE';

export const PROXY_LOGIN = 'PROXY_LOGIN';

export const GET_LEAD_REQUEST = 'GET_LEAD_REQUEST';
export const GET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS';
export const GET_LEAD_FAILURE = 'GET_LEAD_FAILURE';
export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';

export const STATUS_DISTRIBUTOR = 'DISTRIBUTOR';

export const SET_ENERGY_ONLY_ORGANIC = 'SET_ENERGY_ONLY_ORGANIC';
