export default function(customerId) {
  let isValidForm = true;
  const regex = new RegExp('^[0-9]{6,7}$');
  const fieldError = {};

  if (!customerId) isValidForm = false;

  if (!regex.test(customerId)) {
    isValidForm = false;
    fieldError.customerId = 'Please enter a valid Customer id';
  }

  return {
    fieldError,
    isValidForm,
  };
}
