import {
  STORE_NAME,
  SUPPORT_REQUIRED_MANDATORY,
  STATUS_DISTRIBUTOR,
} from './constants';

export class PartnerLoginApi {
  static isPartnerLoggedIn(state) {
    return !!state.get(STORE_NAME).toJS().partnerId;
  }

  static getPartnerId(state) {
    return state.get(STORE_NAME).toJS().partnerId;
  }

  static getPartnerToken(state) {
    return state.get(STORE_NAME).toJS().partnerToken;
  }

  static getPartnerRefreshToken(state) {
    return state.get(STORE_NAME).toJS().refreshToken;
  }

  static getPartnerName(state) {
    return state.getIn([STORE_NAME, 'profile', 'displayName']);
  }

  static getPartnerEmail(state) {
    return state.get(STORE_NAME).toJS().profile.email;
  }

  static getPartnerRelationship(state) {
    return state.get(STORE_NAME).toJS().fields.customerRelationship;
  }

  static getPartnerRelationshipDetails(state) {
    return state.get(STORE_NAME).toJS().fields.referrer;
  }

  static getPartnerStartDate(state) {
    return state.get(STORE_NAME).toJS().profile.startDate;
  }

  static getSupportingId(state) {
    return state.get(STORE_NAME).toJS().supportingPartnerId;
  }

  static getSupportingIdToken(state) {
    return state.get(STORE_NAME).toJS().supportingIdAccessToken;
  }

  static getHostedId(state) {
    return state.get(STORE_NAME).toJS().hostedPartner.id;
  }

  static getHostedPartnerName(state) {
    return state.get(STORE_NAME).toJS().hostedPartner.displayName;
  }

  static createToken(type, token) {
    return `${type} ${token}`;
  }

  static getSupportRequiredStatus(state) {
    return state.getIn([STORE_NAME, 'profile', 'supportRequired'], 'NONE');
  }

  static canSupportPartner(state) {
    const supportRequired = PartnerLoginApi.getSupportRequiredStatus(state);
    const status = state.getIn([STORE_NAME, 'profile', 'status']);
    const token = state.getIn([STORE_NAME, 'partnerToken']);

    return (
      !!token &&
      supportRequired !== SUPPORT_REQUIRED_MANDATORY &&
      status !== STATUS_DISTRIBUTOR
    );
  }

  static getLead(state) {
    return state.hasIn([STORE_NAME], 'lead')
      ? {
          id: state.getIn([STORE_NAME, 'lead', 'id']),
          code: state.getIn([STORE_NAME, 'lead', 'code']),
        }
      : undefined;
  }

  static getDisplayName(state) {
    const partnerDisplayName = state.getIn([
      STORE_NAME,
      'profile',
      'displayName',
    ]);
    const hostedPartnerDisplayName = state.getIn([
      STORE_NAME,
      'hostedPartner',
      'displayName',
    ]);
    return partnerDisplayName || hostedPartnerDisplayName;
  }

  static getRemoteDisplayName(state) {
    return state.getIn([STORE_NAME, 'remoteDisplayName']);
  }
}
