import Immutable from 'immutable';

import {
  POST_PARTNER_LOGIN_FAILURE,
  POST_PARTNER_LOGIN_REQUEST,
  POST_PARTNER_LOGIN_SUCCESS,
  PARTNER_TOKEN_REFRESHED,
  PARTNER_LOGIN_UPDATE_FIELD,
  PARTNER_LOGOUT,
  REFERRAL_FORM_VALIDATION,
  GET_PARTNER_PROFILE_SUCCESS,
  PARTNER_LOGIN_UPDATE_REFERRAL_FIELD,
  RESET_REFERRER_FIELDS,
  SET_SUPPORTING_ID_DIALOG,
  SET_SUPPORTING_ID,
  SET_HOSTED_ID,
  POST_SUPPORTING_PARTNER_LOGIN_SUCCESS,
  POST_SUPPORTING_PARTNER_LOGIN_REQUEST,
  POST_SUPPORTING_PARTNER_LOGIN_FAILURE,
  SSO_VERIFY_REQUEST,
  SSO_VERIFY_SUCCESS,
  SSO_VERIFY_FAILURE,
  GET_LEAD_REQUEST,
  GET_LEAD_SUCCESS,
  GET_LEAD_FAILURE,
  SET_DISPLAY_NAME,
  SET_ENERGY_ONLY_ORGANIC,
} from './constants';

import {
  createRequestStateObject,
  failedRequestState,
  fetchingRequestState,
  successRequestState,
  sendingRequestState,
} from '../../utils/isRequestInProgress';
import { PartnerLoginApi } from './api';
import { APP_POST_APPLICATION_CREATED_SUCCESS } from '../App/constants';

const POST_SUPPORTING_ID_TOKEN_REQ = 'supportingId';
export const POST_SSO_TOKEN_REQ = 'ssoVerify';
const LEAD_REQ = 'lead';

export const createInitialPartnerState = ({
  partnerToken,
  refreshToken,
  partnerId,
  hostedPartner,
  profile,
}) => ({
  partnerToken: partnerToken || '',
  refreshToken: refreshToken || '',
  partnerId: partnerId || '',
  hostedPartner:
    hostedPartner && hostedPartner.id ? { ...hostedPartner } : { id: '' },
  fields: {
    errors: {},
    isValidForm: false,
    customerRelationship: null,
    referrer: {
      customerId: '',
    },
  },
  profile:
    profile && profile.displayName ? { ...profile } : { displayName: null },
  loading: false,
  expires: 0,
  error: '',
  supportingIdDialogOpen: false,
  supportingPartnerId: null,
  supportingIdAccessToken: null,
  supportingIdError: null,
  ...createRequestStateObject([
    POST_SUPPORTING_ID_TOKEN_REQ,
    POST_SSO_TOKEN_REQ,
  ]),
  lead: null,
  remoteDisplayName: null,
  energyOnlyOrganic: false,
});

const initialState = Immutable.fromJS(createInitialPartnerState({}));

export default function(state = initialState, action) {
  switch (action.type) {
    case POST_PARTNER_LOGIN_REQUEST:
      return state.mergeDeep({
        loading: true,
        partnerToken: '',
        partnerId: '',
      });

    case POST_PARTNER_LOGIN_FAILURE:
      return state.mergeDeep({ loading: false, error: action.error });

    case POST_PARTNER_LOGIN_SUCCESS:
      return state.mergeDeep({
        loading: false,
        partnerToken: PartnerLoginApi.createToken(
          action.tokenType,
          action.token
        ),
        refreshToken: action.refreshToken,
        partnerId: action.partnerId,
        error: '',
        expires: Date.now() + action.expires,
      });

    case PARTNER_TOKEN_REFRESHED:
      return state.mergeDeep({
        partnerToken: action.token,
        refreshToken: action.refreshToken,
      });

    case PARTNER_LOGIN_UPDATE_FIELD:
      return state.mergeDeep({ fields: { [action.field]: action.value } });

    case PARTNER_LOGIN_UPDATE_REFERRAL_FIELD:
      return state.mergeDeep({
        fields: { referrer: { [action.field]: action.value } },
      });

    case GET_PARTNER_PROFILE_SUCCESS:
      return state.merge({ profile: action.profile });

    case PARTNER_LOGOUT:
      return state.mergeDeep({
        fields: {
          customerRelationship: null,
          referrer: {
            customerId: '',
          },
        },
        profile: { displayName: null },
        partnerId: null,
        partnerToken: null,
        refreshToken: null,
      });

    case REFERRAL_FORM_VALIDATION:
      return state
        .setIn(['fields', 'errors'], action.fieldError)
        .setIn(['fields', 'isValidForm'], action.isValidForm);

    case RESET_REFERRER_FIELDS:
      return state.mergeDeep({
        fields: {
          errors: {
            customerId: undefined,
            name: undefined,
            postcode: undefined,
            phone: undefined,
            email: undefined,
          },
          referrer: {
            customerId: '',
          },
        },
      });

    case SET_SUPPORTING_ID_DIALOG:
      return state.merge({
        supportingIdDialogOpen: action.open,
        supportingIdError: null,
      });

    case SET_SUPPORTING_ID:
      return state.merge({ supportingPartnerId: action.supportingPartnerId });

    case POST_SUPPORTING_PARTNER_LOGIN_REQUEST:
      return fetchingRequestState(state, POST_SUPPORTING_ID_TOKEN_REQ).merge({
        supportingIdError: null,
      });

    case POST_SUPPORTING_PARTNER_LOGIN_FAILURE:
      return failedRequestState(state, POST_SUPPORTING_ID_TOKEN_REQ).merge({
        supportingIdError: action.error,
      });

    case POST_SUPPORTING_PARTNER_LOGIN_SUCCESS:
      return successRequestState(state, POST_SUPPORTING_ID_TOKEN_REQ).merge({
        supportingIdAccessToken: PartnerLoginApi.createToken(
          action.tokenType,
          action.accessToken
        ),
        supportingPartnerId: action.supportingPartnerId,
      });

    case SET_HOSTED_ID:
      return state.merge({ hostedPartner: { id: action.hostedId } });

    case APP_POST_APPLICATION_CREATED_SUCCESS:
      return action.hostedPartnerProfile
        ? state.merge({ hostedPartner: action.hostedPartnerProfile })
        : state;

    case SSO_VERIFY_REQUEST:
      return sendingRequestState(state, POST_SSO_TOKEN_REQ);

    case SSO_VERIFY_SUCCESS:
      return successRequestState(state, POST_SSO_TOKEN_REQ).merge({
        partnerToken: action.token,
        partnerId: action.partnerId,
      });

    case SSO_VERIFY_FAILURE:
      return failedRequestState(state, POST_SSO_TOKEN_REQ).merge({
        error: action.error,
      });

    case GET_LEAD_FAILURE:
      return fetchingRequestState(state, LEAD_REQ).merge({
        lead: action.lead,
        leadError: action.error,
      });
    case GET_LEAD_SUCCESS:
      return fetchingRequestState(state, LEAD_REQ).merge({
        lead: action.lead,
      });
    case GET_LEAD_REQUEST:
      return fetchingRequestState(state, LEAD_REQ).merge({
        lead: null,
      });
    case SET_DISPLAY_NAME:
      return state.set('remoteDisplayName', action.name);

    case SET_ENERGY_ONLY_ORGANIC:
      return state.set('energyOnlyOrganic', action.energyOnlyOrganic);

    default:
      return state;
  }
}
