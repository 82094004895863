import { takeLatest, call, put, select } from 'redux-saga/effects';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { trackEvent } from 'app/redux/modules/Analytics/actions';
import { EVENTS } from 'app/lib/analytics/constants';

import * as actions from './actions';
import { postApplicationCompleteRequest } from '../App/actions';
import { ApplicationApi } from '../App/api';
import postFundingPaymentCard from '../../services/postFundingPaymentCard';
import {
  POST_FUNDING_PAYMENT_CARD_REQUEST,
  POST_CNP_PAYMENT_REQUEST,
  POST_CNP_PAYMENT_FAILURE,
} from './constants';

export function* handlePostFundingPaymentCard(action) {
  yield put(actions.postFundingPaymentCardLoading());

  try {
    yield call(
      postFundingPaymentCard,
      action.cardToken,
      action.cardHolderName,
      action.cardType,
      action.cardNumber,
      action.cardExpiry
    );
    yield put(postApplicationCompleteRequest());
    yield put(trackEvent(EVENTS.PAYMENT_SUBMITTED));
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_PAYMENT_CARD);
    yield put(actions.postFundingPaymentCardFailure(error));
    const errorMessage = `We encountered an error while saving your card details. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

export function* handleCNPPaymentRequest() {
  try {
    // Avoid concurrent handling of the payment request
    const [{ loading }, completed] = yield select((state) => [
      ApplicationApi.isComplete(state),
      ApplicationApi.isFinished(state),
    ]);
    if (loading || completed) return;

    yield put(postApplicationCompleteRequest());
  } catch (error) {
    yield put(actions.postFundingPaymentCardFailure(error));
  }
}

export function* handleCNPPaymentFailure(action) {
  const { error } = action;
  Sentry.log(error, ERRORS.UPDATE_CNP_PAYMENT);
  const errorMessage = `We encountered an error while saving your card details. ${error}`;
  yield put(openErrorGlobalDialog(errorMessage));
}

export function* combinedSagas() {
  yield takeLatest(
    POST_FUNDING_PAYMENT_CARD_REQUEST,
    handlePostFundingPaymentCard
  );
  yield takeLatest(POST_CNP_PAYMENT_REQUEST, handleCNPPaymentRequest);
  yield takeLatest(POST_CNP_PAYMENT_FAILURE, handleCNPPaymentFailure);
}
